/**
 * WebApp - Routes
 */

// Core imports
import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect, Route } from 'react-router-dom';

// Routes
import LoadAsync from '_platform/src/utils/LoadAsync';
import PrivateRoute from '_platform/src/utils/PrivateRoute';

const Routes = ({ routes, theme }) => {
  return (
    <Switch>
      <Route
        exact
        path="/"
        component={LoadAsync(() =>
          import(
            /* webpackChunkName: "homepage" */ 'containers/HomePage/HomePage'
          )
        )}
      />
      <Route
        exact
        path="/login/"
        component={LoadAsync(() =>
          import(
            /* webpackChunkName: "loginPage" */ 'containers/LoginPage/LoginPage'
          )
        )}
      />
      {theme.settingsApp.passwordResetEnabled && (
        <Route
          path={theme.settingsApp.passwordResetPagePath || '/password-reset/'}
          component={LoadAsync(() =>
            import(
              /* webpackChunkName: "passwordResetPage" */ 'containers/PasswordResetPage/PasswordResetPage'
            )
          )}
        />
      )}
      <PrivateRoute
        path={theme.settingsApp.logoutPagePath}
        component={LoadAsync(
          // Not exact, value without trailing slash
          () =>
            import(
              /* webpackChunkName: "logout" */ 'containers/LogoutPage/LogoutPage'
            )
        )}
      />
      {theme.settingsApp.mobileAppSSOEnabled ? (
        <Route
          path={theme.settingsApp.mobileAppSSOPagePath || '/sso/'}
          component={LoadAsync(() =>
            import(
              /* webpackChunkName: "mobileAppSSOPage" */ 'containers/MobileAppSSOPage/MobileAppSSOPage'
            )
          )}
        />
      ) : (
        <Route path={theme.settingsApp.mobileAppSSOPagePath || '/sso/'}>
          <Redirect to="/" />
        </Route>
      )}
      {routes &&
        routes.map(
          route =>
            // Map the route if it has a component
            // The user has access to any routes specified, so no need to use
            // PrivateRoute as that would restrict access to anonymous routes.
            // Protect content within the container with PrivateComponent
            // On logout, we request the menu/routes to be updated.
            !!route.component && (
              <Route
                // routes={routes} // PrivateRoute
                // menuId={route.menuId} // PrivateRoute
                key={route.menuId}
                exact={route.routeExact}
                path={route.route}
                component={LoadAsync(() =>
                  import(`containers/${route.component}`)
                )}
              />
            )
        )}
      <Route
        component={LoadAsync(() =>
          import(
            /* webpackChunkName: "notNound" */ 'containers/NotFoundPage/NotFoundPage'
          )
        )}
      />
    </Switch>
  );
};

Routes.propTypes = {
  routes: PropTypes.array,
  theme: PropTypes.object,
};

Routes.defaultProps = {
  routes: undefined,
  theme: { settingsApp: {} },
};

export default Routes;
