import React from 'react';
import PropTypes from 'prop-types';

// Style and SEO
import { css, up, styled, Grid, Row, Col } from '@smooth-ui/core-sc';
import { Link } from 'react-router-dom';

import { withSettings } from 'containers/WebApp/SettingsContext';
import SvgIcon from '_platform/src/components/SvgIcon/SvgIcon';

const FooterWrapper = styled.footer`
  background-image: linear-gradient(to right, #0c1e33, #2b415a);
  color: #fff;
  font-size: 16px;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 2rem 0 1rem 0;
  ${up(
    'md',
    css`
      flex-direction: row;
      justify-content: space-between;
    `
  )};

  .logo-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  ul {
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${up(
      'md',
      css`
        flex-direction: row;
      `
    )};
  }
  li {
    font-size: 16px;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    ${up(
      'md',
      css`
        border-right: 1px solid #fff;
        height: 25px;
        padding: inherit;
        text-align: left;
        &:nth-last-child(1) {
          border-right: none;
        }
      `
    )};
    a {
      color: #fff;
      font-weight: normal;
      text-decoration: none;
      padding: 0.1rem 1rem;
      &:hover {
        text-decoration: underline;
      }

      ${up(
        'md',
        css`
          padding: 0.1rem 0.65rem;
        `
      )};

      ${up(
        'lg',
        css`
          padding: 0.1rem 1rem;
        `
      )};
    }
  }
`;

const Footer = ({ siteName }) => (
  <FooterWrapper>
    <FooterContainer>
      <div className="logo-wrapper">
        <SvgIcon
          icon="HP-logo"
          scaleWidth="64"
          scaleHeight="64"
          width="76px"
          height="76px"
          fill="#fff"
          title="HP logo"
        />
      </div>

      <ul>
        <li>
          <Link to="/terms">Terms &amp; Conditions</Link>
        </li>
        <li>
          <Link to="/privacy">Privacy Policy</Link>
        </li>
        <li>
          <Link to="/contact">Contact Us</Link>
        </li>
        <li>
          <Link to="/faqs">FAQs</Link>
        </li>
        <li>
          <Link to="/sitemap">Sitemap</Link>
        </li>
      </ul>
      <p className="text--center">&copy; HP Commercial Club 2021</p>
    </FooterContainer>
    <Grid>
      <Row justifyContent="center">
        <Col xs={12} md={9} lg={8} className="text--center spacer--bottom">
          Must be a sales person at an invited managed business partner to
          participate.
          <br />
          The HP Mastercard&reg; is issued by EML Payment Solutions Limited ABN
          30 131 436 532 AFSL 404131 pursuant to license by Mastercard
          Asia/Pacific Pte. Ltd
        </Col>
      </Row>
    </Grid>
  </FooterWrapper>
);

Footer.propTypes = {
  siteName: PropTypes.string,
};

Footer.defaultProps = {
  siteName: 'Lori Program Default',
};

export default withSettings(Footer);
