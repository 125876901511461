/**
 * WebApp constants
 */

export const CARD_BALANCE_REQUEST = 'local/WebApp/CARD_BALANCE_REQUEST';
export const CARD_BALANCE_SUCCESS = 'local/WebApp/CARD_BALANCE_SUCCESS';
export const CARD_BALANCE_ERROR = 'local/WebApp/CARD_BALANCE_ERROR';
export const CART_REQUEST = 'local/WebApp/CART_REQUEST';
export const CART_SUCCESS = 'local/WebApp/CART_SUCCESS';
export const CART_ERROR = 'local/WebApp/CART_ERROR';
export const CUSTOMER_REPS_REQUEST = 'local/WebApp/CUSTOMER_REPS_REQUEST';
export const CUSTOMER_REPS_SUCCESS = 'local/WebApp/CUSTOMER_REPS_SUCCESS';
export const CUSTOMER_REPS_ERROR = 'local/WebApp/CUSTOMER_REPS_ERROR';
export const MENU_REQUEST = 'local/WebApp/MENU_REQUEST';
export const MENU_SUCCESS = 'local/WebApp/MENU_SUCCESS';
export const MENU_ERROR = 'local/WebApp/MENU_ERROR';
export const MENU_UPDATE_ROUTES = 'local/WebApp/MENU_UPDATE_ROUTES';
export const SETTINGS_REQUEST = 'local/WebApp/SETTINGS_REQUEST';
export const SETTINGS_SUCCESS = 'local/WebApp/SETTINGS_SUCCESS';
export const SETTINGS_ERROR = 'local/WebApp/SETTINGS_ERROR';
export const USER_LOGOUT_REQUEST_CLEANUP =
  'local/WebApp/USER_LOGOUT_REQUEST_CLEANUP';
export const WISHLIST_REQUEST = 'local/WebApp/WISHLIST_REQUEST';
export const WISHLIST_SUCCESS = 'local/WebApp/WISHLIST_SUCCESS';
export const WISHLIST_ERROR = 'local/WebApp/WISHLIST_ERROR';
export const WISHLIST_ADDREMOVE_REQUEST =
  'local/WebApp/WISHLIST_ADDREMOVE_REQUEST';
