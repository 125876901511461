/**
 * Global Styles - App
 *
 * Any global styles overrides or additional styles can be included in this file
 */
import React from 'react';
import { createGlobalStyle, Normalize, thd } from '@smooth-ui/core-sc';
import loriGlobalStyles from '_platform/src/theme/globalStyles';

export const GlobalStylesApp = createGlobalStyle`
  ${loriGlobalStyles}

  @font-face{
    font-family:HPSimplifiedRegular;
    font-weight:400;
    src:url('https://www8.hp.com/etc.clientlibs/HPIT-AEM-GLOBALNAV/clientlibs-globalnav/clientlibs-fonts/clientlib-hf-fontface-core/resources/fonts/HPSimplifiedRegular.eot');
    src:url('https://www8.hp.com/etc.clientlibs/HPIT-AEM-GLOBALNAV/clientlibs-globalnav/clientlibs-fonts/clientlib-hf-fontface-core/resources/fonts/HPSimplifiedRegular.woff') format('woff'), url('https://www8.hp.com/etc.clientlibs/HPIT-AEM-GLOBALNAV/clientlibs-globalnav/clientlibs-fonts/clientlib-hf-fontface-core/resources/fonts/HPSimplifiedRegular.ttf') format('truetype')
  }

  @font-face{
    font-family:HPSimplifiedBold;
    font-weight:700;
    src:url('https://www8.hp.com/etc.clientlibs/HPIT-AEM-GLOBALNAV/clientlibs-globalnav/clientlibs-fonts/clientlib-hf-fontface-core/resources/fonts/HPSimplifiedBold.eot');
    src:url('https://www8.hp.com/etc.clientlibs/HPIT-AEM-GLOBALNAV/clientlibs-globalnav/clientlibs-fonts/clientlib-hf-fontface-core/resources/fonts/HPSimplifiedBold.woff') format('woff'), url('https://www8.hp.com/etc.clientlibs/HPIT-AEM-GLOBALNAV/clientlibs-globalnav/clientlibs-fonts/clientlib-hf-fontface-core/resources/fonts/HPSimplifiedBold.ttf') format('truetype')
  }

  @font-face{
    font-family:HPSimplifiedLight;
    font-weight:300;
    src:url('https://www8.hp.com/etc.clientlibs/HPIT-AEM-GLOBALNAV/clientlibs-globalnav/clientlibs-fonts/clientlib-hf-fontface-core/resources/fonts/HpSimplifiedLight.eot');
    src:url('https://www8.hp.com/etc.clientlibs/HPIT-AEM-GLOBALNAV/clientlibs-globalnav/clientlibs-fonts/clientlib-hf-fontface-core/resources/fonts/HpSimplifiedLight.woff') format('woff'),url('https://www8.hp.com/etc.clientlibs/HPIT-AEM-GLOBALNAV/clientlibs-globalnav/clientlibs-fonts/clientlib-hf-fontface-core/resources/fonts/HpSimplifiedLight.ttf') format('truetype')
  }

  @font-face{
    font-family:HPSimplifiedLightItalic;
    font-style:italic;font-weight:300;
    src:url('https://www8.hp.com/etc.clientlibs/HPIT-AEM-GLOBALNAV/clientlibs-globalnav/clientlibs-fonts/clientlib-hf-fontface-core/resources/fonts/HPSimplifiedLightItalic.eot');
    src:url('https://www8.hp.com/etc.clientlibs/HPIT-AEM-GLOBALNAV/clientlibs-globalnav/clientlibs-fonts/clientlib-hf-fontface-core/resources/fonts/HPSimplifiedLightItalic.woff') format('woff'),url('https://www8.hp.com/etc.clientlibs/HPIT-AEM-GLOBALNAV/clientlibs-globalnav/clientlibs-fonts/clientlib-hf-fontface-core/resources/fonts/HPSimplifiedLightItalic.ttf') format('truetype')
  }

  @font-face{
    font-family:HPSimplified;
    font-weight:400;
    src:url('https://www8.hp.com/etc.clientlibs/HPIT-AEM-GLOBALNAV/clientlibs-globalnav/clientlibs-fonts/clientlib-hf-fontface-core/resources/fonts/HPSimplifiedRegular.eot');
    src:url('https://www8.hp.com/etc.clientlibs/HPIT-AEM-GLOBALNAV/clientlibs-globalnav/clientlibs-fonts/clientlib-hf-fontface-core/resources/fonts/HPSimplifiedRegular.woff') format('woff'),url('https://www8.hp.com/etc.clientlibs/HPIT-AEM-GLOBALNAV/clientlibs-globalnav/clientlibs-fonts/clientlib-hf-fontface-core/resources/fonts/HPSimplifiedRegular.ttf') format('truetype')
  }

  @font-face{
    font-family:HPSimplified-Regular;
    font-weight:400;
    src:url('https://www8.hp.com/etc.clientlibs/HPIT-AEM-GLOBALNAV/clientlibs-globalnav/clientlibs-fonts/clientlib-hf-fontface-core/resources/fonts/HPSimplifiedRegular.eot');
    src:url('https://www8.hp.com/etc.clientlibs/HPIT-AEM-GLOBALNAV/clientlibs-globalnav/clientlibs-fonts/clientlib-hf-fontface-core/resources/fonts/HPSimplifiedRegular.woff') format('woff'),url('https://www8.hp.com/etc.clientlibs/HPIT-AEM-GLOBALNAV/clientlibs-globalnav/clientlibs-fonts/clientlib-hf-fontface-core/resources/fonts/HPSimplifiedRegular.ttf') format('truetype')
  }

  @font-face{
    font-family:HPSimplified-Light;
    font-weight:300;
    src:url('https://www8.hp.com/etc.clientlibs/HPIT-AEM-GLOBALNAV/clientlibs-globalnav/clientlibs-fonts/clientlib-hf-fontface-core/resources/fonts/HpSimplifiedLight.eot');
    src:url('https://www8.hp.com/etc.clientlibs/HPIT-AEM-GLOBALNAV/clientlibs-globalnav/clientlibs-fonts/clientlib-hf-fontface-core/resources/fonts/HpSimplifiedLight.woff') format('woff'),url('https://www8.hp.com/etc.clientlibs/HPIT-AEM-GLOBALNAV/clientlibs-globalnav/clientlibs-fonts/clientlib-hf-fontface-core/resources/fonts/HpSimplifiedLight.ttf') format('truetype')
  }

  @import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;700&display=swap');

  body {
    font-family: HPSimplifiedRegular, 'Public Sans', Tahoma, sans-serif;
    line-height: 1.6;
  }

  /* Custom CSS here */
  h1 {
    font-size: 48px;
    font-weight: 400;
    font-variation-settings: 'wght' 400;
    line-height: 1.25;
    margin-bottom: .25em;
  }

  h2 {
    font-weight: normal;
    font-size: 40px;
    line-height: 1.25;
  }

  a {
    color: #2B415A;

    &:hover {
      color: #88A0A8;
    }

    &:visited {
      color: #88A0A8;
    }
  }

  /* Don't wrap emails, phones or other non-wrapping strings */
  .nowrap,
  .date,
  .email,
  .phone {
    white-space: nowrap;
  }

  /* Color text */
  .text--red {
    color: ${thd('danger', '#dc3545')};
  }

  .text--white {
    color: #fff;
  }

  .text--primary {
    color: ${thd('primary', '#867455')};
  }

  /* Align text */
  .text-center,
  .text-centre,
  .text--center,
  .text--centre {
    text-align: center;
  }
  .text-right,
  .text--right {
    text-align: right;
  }
  .text-left,
  .text--left {
    text-align: left;
  }

  .text-small,
  .text--small {
    font-size: 80%;
  }

  .banner-text-small,
  .banner--text--small {
    font-size: 20px;
    font-weight: normal;
    text-align: left;
  }

  .text-large,
  .text--large {
    font-size: 120%;
  }

  .text-larger,
  .text--larger {
    font-size: 200%;
  }

  .text-normal,
  .text--normal {
    font-weight: normal;
  }
  .text-uppercase,
  .text--uppercase {
    text-transform: uppercase;
  }

  .text--tight {
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0;
  }

  /* Handy spacing classes */
  :root {
    --spacer: 2rem;
  }

  .spacer {
    padding-bottom: 40px;
    padding-top: 40px;
    padding-bottom: var(--spacer);
    padding-top: var(--spacer);
  }
  .spacer--small {
    padding-bottom: 20px;
    padding-top: 20px;
    padding-bottom: calc(var(--spacer) / 2);
    padding-top: calc(var(--spacer) / 2);
  }
  .spacer--large {
    padding-bottom: 80px;
    padding-top: 80px;
    padding-bottom: calc(var(--spacer) * 2);
    padding-top: calc(var(--spacer) * 2);
  }
  .spacer--around {
    padding: 40px;
    padding: var(--spacer);
  }
  .spacer--around--small {
    padding: 20px;
    padding: calc(var(--spacer) / 2);
  }
  .spacer--around--large {
    padding: 80px;
    padding: calc(var(--spacer) * 2);
  }
  .spacer--top {
    padding-top: 40px;
    padding-top: var(--spacer);
  }
  .spacer--bottom {
    padding-bottom: 40px;
    padding-bottom: var(--spacer);
  }
  .spacer--top--small,
  .spacer--small--top {
    padding-top: 20px;
    padding-top: calc(var(--spacer) / 2);
  }
  .spacer--bottom--small,
  .spacer--small--bottom {
    padding-bottom: 20px;
    padding-bottom: calc(var(--spacer) / 2);
  }
  .spacer--top--large,
  .spacer--large--top {
    padding-top: 80px;
    padding-top: calc(var(--spacer) * 2);
  }
  .spacer--bottom--large,
  .spacer--large--bottom {
    padding-bottom: 80px;
    padding-bottom: calc(var(--spacer) * 2);
  }

  .Toastify .Toastify__toast--success {
    background: #07bc0c;
  }

  .DayPickerInput-Overlay {
    color: #343a40;
  }
`;

const StylesApp = () => (
  <React.Fragment>
    <Normalize />
    <GlobalStylesApp />
  </React.Fragment>
);

export default StylesApp;
