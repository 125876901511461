import React from 'react';
import PropTypes from 'prop-types';

import svgSprites from 'theme/svg-sprites.svg';

const SvgIcon = ({
  icon,
  scaleWidth,
  scaleHeight,
  width,
  height,
  fill,
  color,
  title,
}) => (
  <svg
    viewBox={`0 0 ${scaleWidth} ${scaleHeight}`}
    className={`icon icon--${icon}`}
    style={{
      color,
      fill: fill || 'currentColor',
      height,
      width,
    }}
  >
    <title>{title || icon || 'Icon'}</title>
    <use xlinkHref={`${svgSprites}#${icon}`} />
  </svg>
);

SvgIcon.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  icon: PropTypes.string.isRequired, // Select icon from sprite
  scaleHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  scaleWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  title: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

SvgIcon.defaultProps = {
  color: undefined,
  fill: undefined,
  title: undefined,
};

export default SvgIcon;
