/**
 * WebApp reducer
 */

import {
  CARD_BALANCE_REQUEST,
  CARD_BALANCE_SUCCESS,
  CARD_BALANCE_ERROR,
  CART_REQUEST,
  CART_SUCCESS,
  CART_ERROR,
  CUSTOMER_REPS_REQUEST,
  CUSTOMER_REPS_SUCCESS,
  CUSTOMER_REPS_ERROR,
  MENU_REQUEST,
  MENU_SUCCESS,
  MENU_ERROR,
  MENU_UPDATE_ROUTES,
  SETTINGS_REQUEST,
  SETTINGS_SUCCESS,
  SETTINGS_ERROR,
  USER_LOGOUT_REQUEST_CLEANUP,
  WISHLIST_REQUEST,
  WISHLIST_SUCCESS,
  WISHLIST_ERROR,
} from './constants';

export const initialState = {
  cardBalance: undefined,
  cart: undefined,
  customerReps: undefined,
  menu: null,
  routes: (localStorage.routes && JSON.parse(localStorage.routes)) || undefined,
  settings: {},
  wishlist: undefined,
};

function webAppReducer(state = initialState, action) {
  switch (action.type) {
    case CARD_BALANCE_REQUEST:
      if (action.refresh) {
        return state;
      }

      return {
        ...state,
        cardBalance: state.cardBalance || 0,
      };
    case CARD_BALANCE_SUCCESS:
      return {
        ...state,
        cardBalance: action.response,
      };
    case CARD_BALANCE_ERROR:
      return {
        ...state,
        cardBalance: initialState.cardBalance,
      };
    case CART_REQUEST:
      if (action.refresh) {
        return state;
      }

      return {
        ...state,
        cart: { ...state.cart, isLoading: true } || { isLoading: true },
      };
    case CART_SUCCESS:
      return {
        ...state,
        cart: action.response,
      };
    case CART_ERROR:
      return {
        ...state,
        cart: initialState.cart,
      };
    case CUSTOMER_REPS_REQUEST:
      return {
        ...state,
        customerReps: state.customerReps || {},
      };
    case CUSTOMER_REPS_SUCCESS:
      return {
        ...state,
        customerReps: {
          data: action.response,
          lastUpdated: action.lastUpdated,
        },
      };
    case CUSTOMER_REPS_ERROR:
      return {
        ...state,
        customerReps: initialState.customerReps,
      };
    case MENU_ERROR:
      return {
        ...state,
        menu: initialState.menu,
      };
    case MENU_REQUEST:
      if (action.refresh) {
        return state;
      }

      return {
        ...state,
        menu: [],
      };
    case MENU_SUCCESS:
      return {
        ...state,
        menu: action.response,
      };
    case MENU_UPDATE_ROUTES:
      return {
        ...state,
        routes: action.response,
      };
    case SETTINGS_ERROR:
      return {
        ...state,
        settings: initialState.settings,
      };
    case SETTINGS_REQUEST:
      if (action.refresh) {
        return state;
      }

      return {
        ...state,
        settings: {
          lastUpdated: null,
        },
      };
    case SETTINGS_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.response,
          lastUpdated: action.lastUpdated,
        },
      };
    case USER_LOGOUT_REQUEST_CLEANUP:
      return {
        ...state,
        cardBalance: initialState.cardBalance,
        cart: initialState.cart,
        customerReps: initialState.customerReps,
        wishlist: initialState.wishlist,
      };
    case WISHLIST_REQUEST:
      if (state.wishlist) {
        return state; // Perf
      }

      return {
        ...state,
        wishlist: [],
      };
    case WISHLIST_SUCCESS:
      return {
        ...state,
        wishlist: action.response,
      };
    case WISHLIST_ERROR:
      return {
        ...state,
        wishlist: initialState.wishlist,
      };
    default:
      return state;
  }
}

export default webAppReducer;
