/**
 * Web App actions
 */

import {
  CARD_BALANCE_REQUEST,
  CARD_BALANCE_SUCCESS,
  CARD_BALANCE_ERROR,
  CART_REQUEST,
  CART_SUCCESS,
  CART_ERROR,
  CUSTOMER_REPS_REQUEST,
  CUSTOMER_REPS_SUCCESS,
  CUSTOMER_REPS_ERROR,
  MENU_REQUEST,
  MENU_SUCCESS,
  MENU_ERROR,
  MENU_UPDATE_ROUTES,
  SETTINGS_REQUEST,
  SETTINGS_SUCCESS,
  SETTINGS_ERROR,
  USER_LOGOUT_REQUEST_CLEANUP,
  WISHLIST_REQUEST,
  WISHLIST_SUCCESS,
  WISHLIST_ERROR,
  WISHLIST_ADDREMOVE_REQUEST,
} from './constants';

/**
 * CardBalance request - cardBalance the request saga
 *
 * @returns {object} An action object with a type of CARD_BALANCE_REQUEST
 */
export function cardBalanceRequest(refresh) {
  return {
    type: CARD_BALANCE_REQUEST,
    refresh,
  };
}

/**
 * Dispatched when cardBalanceRequest succeeds by the request saga
 *
 * @param {object} response The response data
 * @param {number} lastUpdated Unix timestamp
 * @returns {object}         An action object with a type of CARD_BALANCE_SUCCESS and the response
 */
export function cardBalanceSuccess(response, lastUpdated) {
  return {
    type: CARD_BALANCE_SUCCESS,
    response,
    lastUpdated,
  };
}

/**
 * Dispatched when cardBalanceRequest fails
 *
 * @param {object} error The error as received from the API
 * @returns {object}     An action object with a type of CARD_BALANCE_ERROR and the error
 */
export function cardBalanceError(error) {
  return {
    type: CARD_BALANCE_ERROR,
    error,
  };
}

/**
 * Cart request - start the request saga
 * The reason this is here is because it needs to be called from CartProvider
 * as well as RewardsProvider saga (after adding item to cart)
 *
 * @returns {object} An action object with a type of CART_REQUEST
 */
export function cartRequest(refresh) {
  return {
    type: CART_REQUEST,
    refresh,
  };
}

/**
 * Dispatched when cartRequest succeeds by the request saga
 *
 * @param {object} response The response data
 * @param {number} lastUpdated Unix timestamp
 * @returns {object}         An action object with a type of CART_SUCCESS and the response
 */
export function cartSuccess(response, lastUpdated) {
  return {
    type: CART_SUCCESS,
    response,
    lastUpdated,
  };
}

/**
 * Dispatched when cartRequest fails
 *
 * @param {object} error The error as received from the API
 * @returns {object}     An action object with a type of CART_ERROR and the error
 */
export function cartError(error) {
  return {
    type: CART_ERROR,
    error,
  };
}

/**
 * CustomerReps request - start the request saga
 *
 * @returns {object} An action object with a type of CUSTOMER_REPS_REQUEST
 */
export function customerRepsRequest() {
  return {
    type: CUSTOMER_REPS_REQUEST,
  };
}

/**
 * Dispatched when customerRepsRequest succeeds by the request saga
 *
 * @param {object} response The response data
 * @param {number} lastUpdated Unix timestamp
 * @returns {object}         An action object with a type of CUSTOMER_REPS_SUCCESS and the response
 */
export function customerRepsSuccess(response, lastUpdated) {
  return {
    type: CUSTOMER_REPS_SUCCESS,
    response,
    lastUpdated,
  };
}

/**
 * Dispatched when customerRepsRequest fails
 *
 * @param {object} error The error as received from the API
 * @returns {object}     An action object with a type of CUSTOMER_REPS_ERROR and the error
 */
export function customerRepsError(error) {
  return {
    type: CUSTOMER_REPS_ERROR,
    error,
  };
}

/**
 * Menu request - start the request saga
 *
 * @returns {object} An action object with a type of MENU_REQUEST
 */
export function menuRequest(refresh) {
  return {
    type: MENU_REQUEST,
    refresh,
  };
}

/**
 * Dispatched when menuRequest succeeds by the request saga
 *
 * @param {object} response The response data
 * @returns {object}         An action object with a type of MENU_SUCCESS and the response
 */
export function menuSuccess(response) {
  return {
    type: MENU_SUCCESS,
    response,
  };
}

/**
 * Dispatched when menuRequest fails
 *
 * @param {object} error The error as received from the API
 * @returns {object}     An action object with a type of MENU_ERROR and the error
 */
export function menuError(error) {
  return {
    type: MENU_ERROR,
    error,
  };
}

/**
 * Dispatched in combination with menuSuccess in order to set up routes
 *
 * @param {object} response The response data
 * @returns {object}        An action object with a type of MENU_UPDATE_ROUTES and the response
 */
export function menuUpdateRoutes(response) {
  return {
    type: MENU_UPDATE_ROUTES,
    response,
  };
}

/**
 * Settings request - start the request saga
 *
 * @returns {object} An action object with a type of SETTINGS_REQUEST
 */
export function settingsRequest(refresh) {
  return {
    type: SETTINGS_REQUEST,
    refresh,
  };
}

/**
 * Dispatched when settingsRequest succeeds by the request saga
 *
 * @param {object} response The response data
 * @param {number} lastUpdated Unix timestamp
 * @returns {object}         An action object with a type of SETTINGS_SUCCESS and the response
 */
export function settingsSuccess(response, lastUpdated) {
  return {
    type: SETTINGS_SUCCESS,
    response,
    lastUpdated,
  };
}

/**
 * Dispatched when settingsRequest fails
 *
 * @param {object} error The error as received from the API
 * @returns {object}     An action object with a type of SETTINGS_ERROR and the error
 */
export function settingsError(error) {
  return {
    type: SETTINGS_ERROR,
    error,
  };
}

/**
 * Dispatched from the userLogoutListener saga
 * Not hooking directly into `USER_LOGOUT_REQUEST` because it would refresh all
 * the values before the user is logged out
 *
 * @returns {object}  An action object with a type of USER_LOGOUT_REQUEST_CLEANUP
 */
export function userLogoutClearUserData() {
  return { type: USER_LOGOUT_REQUEST_CLEANUP };
}

/**
 * Wishlist request - start the request saga
 * The reason this is here is because it needs to be called from WishlistProvider
 * as well as RewardsProvider saga (after adding item to wishlist)
 *
 * @returns {object} An action object with a type of WISHLIST_REQUEST
 */
export function wishlistRequest(refresh) {
  return {
    type: WISHLIST_REQUEST,
    refresh,
  };
}

/**
 * Dispatched when wishlistRequest succeeds by the request saga
 *
 * @param {object} response The response data
 * @param {number} lastUpdated Unix timestamp
 * @returns {object}         An action object with a type of WISHLIST_SUCCESS and the response
 */
export function wishlistSuccess(response, lastUpdated) {
  return {
    type: WISHLIST_SUCCESS,
    response,
    lastUpdated,
  };
}

/**
 * Dispatched when wishlistRequest fails
 *
 * @param {object} error The error as received from the API
 * @returns {object}     An action object with a type of WISHLIST_ERROR and the error
 */
export function wishlistError(error) {
  return {
    type: WISHLIST_ERROR,
    error,
  };
}

/**
 * Wishlist add/remove request - start the request saga
 *
 * @param {string} action Either 'add' or 'remove' - Remove being the default
 * @param {object} values Reward properties
 * @returns {object} An action object with a type of WISHLIST_ADDREMOVE_REQUEST
 */
export function wishlistAddRemoveRequest(action, values) {
  return {
    type: WISHLIST_ADDREMOVE_REQUEST,
    action,
    values,
  };
}
